<template>
  <div class="card">
    <div class="card-body text-center d-flex align-items-center flex-column">
      <div class="card--cae--content mb-auto">
        <!-- <div class="mb-1">
          <img
            src="../../../../app-assets/images/escudo-seguro.png"
            alt="name category"
            class="round"
            height="40"
            width="40"
          >
        </div> -->
        <h3>{{ section.name }}</h3>
        <template v-if="section.description">
          <p
            class="mb-0"
            v-html="displayedText"
          />
          <a
            v-if="displayedText.length > 50"
            href="#"
            @click="isShowingMore = !isShowingMore;"
          >
            {{ buttonText }}
          </a>
        </template>
        <p class="text-muted">
          <template v-if="section.subsections.length > 0">
            {{ section.subsections.length }} subsections /
          </template>
          {{ section.documents }} documents
        </p>
        <div
          v-if="section.subsections.length > 0"
          class="mb-1"
        >
          <v-select
            class="center-placeholder"
            label="name"
            :options="section.subsections"
            :get-option-key="option => option.name"
            :placeholder="'Access to subsection'"
            @input="enterSection($event)"
          />
        </div>
      </div>
      <a
        class="btn btn-small btn-primary"
        @click="$router.push({name:'cae.view', params: {id: section.id}, query: {host: (institution ? institution.id : null), actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser}})"
      >View documents</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {
      isShowingMore: false,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      institution: 'cae/institution',
    }),
    buttonText() {
      return this.isShowingMore ? 'Show less' : 'Show more'
    },
    displayedText() {
      const div = document.createElement('div')
      div.innerHTML = this.section.description

      if (this.isShowingMore || div.innerText.length < 500) {
        return this.section.description
      }

      return div.innerText.slice(0, 500) + (div.innerText.length > 500 ? '...' : '')
    },
  },
  mounted() {},
  methods: {
    enterSection(section) {
      this.$router.push({ name: 'cae.view', params: { id: section.id }, query: { host: (this.institution ? this.institution.id : null), actAs: this.$route.query.actAs, prefilterUser: this.$route.query.prefilterUser } })
    },
  },
}
</script>
